<template>
	<div class="row" v-show="showUploadButton">
		<div class="col-12 text-center py-3 d-grid">
			<button class="btn btn-outline-secondary" @click="$refs.file.click()">
				<i class="bi bi-image me-2"></i> Upload image
			</button>
			<input class="form-control" v-show="false" 
			type="file" ref="file" @change="loadImage($event)" 
			accept="image/*" :multiple="uploadMultiple" />
		</div>
	</div>
	<div class="row">
		<div class="col-12 position-relative d-grid">
			<div class="position-absolute top-50 start-50 translate-middle w-75" v-show="showProgressBar">
				<div class="progress" style="height: 25px;">
					<div class="progress-bar bg-dark" role="progressbar" 
					aria-valuenow="50" :style="`width: ${progress}%;`"
					aria-valuemin="0" aria-valuemax="100">
						Uploading - {{progress}}%
					</div>
				</div>
			</div>
			<div align="right" v-show="img">
				<button class="btn btn-link text-danger"
				@click="btnRemoveImageClick">
					<i class="bi bi-trash"></i> Remove image
				</button>
				<img :src="img" class="img-fluid rounded" />
			</div>
		</div>
	</div>
	<div class="row text-white bg-dark" v-show="showCropper">
		<div class="col-12 my-2 border1 border-light rounded-pill text-center">
			<button class="btn btn-link text-reset cropper-toolbar-item "
			@click="rotate(-90)">
				<i class="bi bi-arrow-counterclockwise"></i>
			</button>
			<button class="btn btn-link text-reset cropper-toolbar-item "
			@click="rotate(90)">
				<i class="bi bi-arrow-clockwise"></i>
			</button>
			<button class="btn btn-link text-reset cropper-toolbar-item "
			@click="flip(false, true)">
				<i class="bi bi-hr"></i>
			</button>
			<button class="btn btn-link text-reset cropper-toolbar-item"
			@click="flip(true, false)">
				<i class="bi bi-vr"></i>
			</button>
			<button class="btn btn-link text-reset cropper-toolbar-item"
			@click="crop">
				<i class="bi bi-crop"></i>
			</button>
			<button class="btn btn-link text-danger cropper-toolbar-item"
			@click="reset">
				<i class="bi bi-x-circle"></i>
			</button>
		</div>
		<div class="col-12">	
			<Cropper
				ref="cropper"
				class="cropper"
				imageRestriction="none"
				:src="image.src"
				:stencil-props="{
					aspectRatio: 1
				}"
			/>
		</div>
	</div>
</template>
<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import Compress from 'compress.js';
const compress = new Compress();
const  getMimeType = (file, fallback = null) => {
	const byteArray = (new Uint8Array(file)).subarray(0, 4);
    let header = '';
    for (let i = 0; i < byteArray.length; i++) {
       header += byteArray[i].toString(16);
    }
	switch (header) {
        case "89504e47":
            return "image/png";
        case "47494638":
            return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            return "image/jpeg";
        default:
            return fallback;
    }
}
export default {
	name: 'image-uploader',
	components: {
		Cropper,
	},
	emits: ["setDownloadUrl"],
	props:{
		folder: {
			type: String,
			default: "dump",
		},
		baseFilename: {
			type: String,
			default: "soca_islands",
		},
		uploadFileType: {
			type: String,
			default: "image",
		},
		uploadMultiple: {
			type: Boolean,
			default: false,
		},
		cacheControl: {
			type: String,
			default: "public,max-age=2629746",	
		},
	},
	data() {
		return{
			showUploadButton: true,
			showProgressBar: false,
			showCropper: false,
			progress: 0,
			image: {
				src: null,
				type: null,
				fileName: null,
				downloadURL: null,
			},
			img: "",
		}
	},
	methods: {
		change({ coordinates, canvas }) {
			console.log(coordinates, canvas)
		},
		btnRemoveImageClick(){
			this.img = "";
			this.progress = 0;
			this.showCropper = false;
			this.showProgressBar = false;
			this.showUploadButton = true;
		},
		crop() {
			this.showCropper = false;
			this.showProgressBar = true;
			const { canvas } = this.$refs.cropper.getResult();
			this.img = canvas.toDataURL();
			canvas.toBlob(async (blob) => {
				const { folder, baseFilename, uploadFileType } = this;
				const storage = getStorage();
				const metadata = {
					contentType: 'image/jpeg',
					cacheControl: this.cacheControl,
					customMetadata: {
						cacheControl: this.cacheControl,
					},
				};

				//compress image
				const compressedImage = await compress.compress([blob], {
					size: .1, 
					quality: .3, 
					width: 1000, 
					maxWidth: 1920, 
					maxHeight: 1920, 
					resize: true, 
					rotate: false, 
				}).then((data) => {
					return data[0]?.data;
				})

				const compressedFile = Compress.convertBase64ToFile(compressedImage, 'jpg');
				const fileName = uploadFileType == "profile" ? `${ folder }/${ baseFilename }.jpg` : `${ folder }/${ baseFilename }_${ Date.now() }.jpg`;
				const storageRef = ref(storage, fileName);
				const uploadTask = uploadBytesResumable(storageRef, compressedFile, metadata);
				this.image.fileName = fileName;
				
				uploadTask.on('state_changed', 
					(snapshot) => {
						const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						this.progress = progress;
						switch (snapshot.state) {
						case 'paused':
							break;
						case 'running':
							break;
						}
					}, 
					(error) => {
						console.log(error);
					}, 
					() => {
						getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
							this.image.downloadURL = downloadURL;
							this.showProgressBar = false;
							this.$emit('setDownloadUrl', this.image)
						});
					}
				);
			}, this.image.type);
		},
		flip(x,y) {
			this.$refs.cropper.flip(x,y);
		},
		rotate(angle) {
			this.$refs.cropper.rotate(angle);
		},
		reset(){
			this.image = {
				src: null,
				type: null,
				fileName: null,
			};
			this.showCropper = false;
			this.$refs.file.value = null;
			this.showUploadButton = true;
		},
		loadImage(event) {
			const { files } = event.target;
			if (files && files[0]) {
				this.showCropper = true;
				this.showUploadButton = false;
				if (this.image.src) {
					URL.revokeObjectURL(this.image.src)
				}
				const blob = URL.createObjectURL(files[0]);	
				const reader = new FileReader();
				reader.onload = (e) => {
					this.image = {
						src: blob,
						type: getMimeType(e.target.result, files[0].type),
					};
				};
				reader.readAsArrayBuffer(files[0]);
			}
		},
	},
	mounted(){
	},
}
</script>
<style scoped>
.cropper {
	height: 300px;
	width: 100%;
	background: #DDD;
}
.cropper-toolbar-item{
	font-size: 1.5rem;
}
</style>